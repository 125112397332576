<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contacto</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">España</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+34 644 54 55 88</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@clasesdeprogramacion.net">info@clasesdeprogramacion.net</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/profile.php?id=100095006798132" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                        <li><a href="https://www.instagram.com/clasesdeprogramacion.net_erc" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
<!--                         <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <!-- <div class="single-footer-widget mb-30">
                    <h3>Soporte</h3>
                <ul class="support-link">
                    <li><a routerLink="/">Privacidad</a></li>
                    <li><a routerLink="/">Preguntas Frecuentes</a></li>
                    <li><a routerLink="/">Soporte</a></li>
                    <li><a routerLink="/">Términos</a></li>
                    <li><a routerLink="/">Condiciones</a></li>
                    <li><a routerLink="/">Política</a></li>
                </ul>
            </div> -->
    </div>
    <div class="col-lg-2 col-md-6 col-sm-6">
       <!--  <div class="single-footer-widget mb-30">
            <h3>Enlaces Útiles</h3>
            <ul class="useful-link">
                <li><a routerLink="/">Diseño Web</a></li>
                <li><a routerLink="/">Diseño UI/UX</a></li>
                <li><a routerLink="/">Desarrollo WP</a></li>
                <li><a routerLink="/">Desarrollo de Aplicaciones</a></li>
                <li><a routerLink="/">Documentación</a></li>
                <li><a routerLink="/">Desarrollo Web</a></li>
            </ul>
        </div> -->
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <!-- <div class="single-footer-widget mb-30">
            <h3>Boletín Informativo</h3>
            <div class="newsletter-box">
                <p>Para recibir las últimas noticias y actualizaciones de nosotros.</p>
                <form class="newsletter-form">
                    <label>Su dirección de correo electrónico:</label>
                    <input type="email" class="input-newsletter" placeholder="Ingrese su email" name="EMAIL">
                    <button type="submit">Suscribirse</button>
                </form>
            </div>
        </div> -->
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="../../../../assets/img/logo/LOGO_CUADRADO.png" alt="image"></a>
            </div> -->
            <p>© clasesdeprogramacion.net es un proyecto de <a href="https://clasesdeprogramacion.net/" target="_blank">Eleazar Ramos Cortés</a></p>
        </div>
    </div>


<ngx-scrolltop></ngx-scrolltop>