import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth, private router: Router) { }

    registerWithEmail(email: string, password: string) {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          // El usuario ha sido creado exitosamente
          console.log(userCredential.user);
          userCredential.user.sendEmailVerification();
        })
        .catch(error => {
          // Ocurrió un error al crear el usuario
          console.error(error);
        });
    }

    registerGoogle() {
      this.afAuth.signInWithPopup(new GoogleAuthProvider())
          .then(result => {
          // El usuario ha iniciado sesión correctamente con Google
          console.log(result.user);
          if (!result.user.emailVerified) {
            result.user.sendEmailVerification();
          }
      })
      .catch(error => {
        // Ocurrió un error al iniciar sesión con Google
        console.error(error);
      });
    }

    loginWithEmail(email: string, password: string) {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then(userCredential => {
          // El usuario ha iniciado sesión exitosamente
          console.log('Inicio de sesión exitoso:', userCredential.user);
          this.router.navigate(['/protected-page']);
  
          // Puedes añadir cualquier lógica adicional aquí, como redirigir al usuario
        })
        .catch(error => {
          // Ocurrió un error al iniciar sesión
          console.error(error);
        });
    }
  
    loginWithGoogle() {
      this.afAuth.signInWithPopup(new GoogleAuthProvider())
        .then(result => {
          console.log('Inicio de sesión exitoso:', result.user);
          this.router.navigate(['/protected-page']);
        })
        .catch(error => {
          console.error(error);
        });
    }
    
    logout() {
      return this.afAuth.signOut();
    }

    getAuthState() {
      return this.afAuth.authState;
    }
}
