<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Conviértete en Instructor</h2>
                    <p>¿Tienes habilidades y pasión por enseñar? Únete a nuestra comunidad de instructores y comparte tu conocimiento en programación y tecnología. Ayuda a otros a crecer mientras te desarrollas profesionalmente.</p>
                    <a routerLink="/login" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Inscríbete Ahora</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="../../../../assets/img/index/instructor.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="../../../../assets/img/index/socio.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Conviértete en Socio</h2>
                    <p>¿Estás buscando colaborar con un equipo dinámico y en crecimiento? Forma parte de nuestra red de socios y juntos impulsaremos la educación en tecnología a nuevos horizontes. Únete a nosotros en esta emocionante aventura</p>
                    <a routerLink="/login" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Contacto</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>