// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD-yL-AT71hpDwwFNEZwcFFliXZObskYBM",
    authDomain: "clasesdeprogramacion-2adeb.firebaseapp.com",
    projectId: "clasesdeprogramacion-2adeb",
    storageBucket: "clasesdeprogramacion-2adeb.appspot.com",
    messagingSenderId: "218942191228",
    appId: "1:218942191228:web:d9000cca1b535785f97bf9",
    measurementId: "G-BNJQWSC8L6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
