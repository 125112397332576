<div class="feedback-slides">
    <owl-carousel-o [options]="feedbackSlides">
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“Este curso de Angular transformó mi carrera. Los instructores son expertos y siempre estuvieron dispuestos a ayudar. ¡Recomendado!”</p>
                <div class="info">
                    <h3>Laura Pérez</h3>
                    <span>México</span>
                    <img src="../../../../assets/img/index/Laura Pérez.png" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“He tomado muchos cursos en línea, pero este de Angular ha sido el mejor. La calidad y la metodología son excelentes. Ya estoy aplicando lo aprendido en mi trabajo.”</p>
                <div class="info">
                    <h3>José Hernández</h3>
                    <span>Colombia</span>
                    <img src="../../../../assets/img/index/José Hernández.png" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“Si estás buscando una formación real en tecnologías web, no busques más. Los cursos aquí son de alta calidad y los instructores saben de lo que hablan.”</p>
                <div class="info">
                    <h3>María González</h3>
                    <span>España</span>
                    <img src="../../../../assets/img/index/María González.png" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
