import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as emailjs from 'emailjs-com';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    contactForm: FormGroup;
    errorMessage: string | null = null;

    constructor(private fb: FormBuilder, 
        private router: Router,
    private http: HttpClient) {
        this.contactForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
            email: ['', [Validators.required, Validators.email]],
            number: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required],
        });
    }

    ngOnInit(): void {
    }

    submit() {
        if (this.contactForm.valid) {
            const formData = new FormData();
            formData.append('name', this.contactForm.controls.name.value);
            formData.append('email', this.contactForm.controls.email.value);
            formData.append('number', this.contactForm.controls.number.value);
            formData.append('subject', this.contactForm.controls.subject.value);
            formData.append('message', this.contactForm.controls.message.value);
    
            this.http.post('/contactoscp.php', formData).subscribe(
                response => {
                    this.router.navigate(['/']);
                },
                error => {
                    this.errorMessage = "Ha ocurrido un error al enviar el mensaje. Por favor, inténtelo de nuevo o contacte directamente al soporte. +34 644 54 55 88";
                }
            );
        }
    }
}
