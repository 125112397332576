<div class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="assets/img/registro/estudiante.svg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/logo/logo.webp" alt="image"></a>
                            </div>
                            <h3>Abre tu cuenta en clasesdeprogramacion.net</h3>
                            <p>¿Ya estás registrado? <a routerLink="/login">Login</a></p>
                            <form id="registerForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" placeholder="Tú correo electronico" class="form-control" formControlName="email">
                                </div>
                                <div *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)" class="error-message">
                                    <div *ngIf="registerForm.controls['email'].errors.required">El correo electrónico es requerido</div>
                                    <div *ngIf="registerForm.controls['email'].errors.email">El correo electrónico debe ser válido</div>
                                  </div>
                                <div class="form-group">
                                    <input type="password" name="password" id="password" placeholder="Tú contraseña" class="form-control" formControlName="password">
                                </div>
                                <div *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)" class="error-message">
                                    <div *ngIf="registerForm.controls['password'].errors.required">La contraseña es requerida</div>
                                    <div *ngIf="registerForm.controls['password'].errors.minlength">La contraseña debe tener al menos 6 caracteres</div>
                                  </div>
                                <button type="submit" [disabled]="registerForm.invalid">Registrarse</button>
                                
                                <div class="connect-with-social">
                                    <span>O</span>
                                   <!--  <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Conecta con Facebook</button> -->
                                    <button type="button" (click)="registerGoogle()" class="google"><i class='bx bxl-google'></i> Conecta con Google</button>
                                </div>

                            </form>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>