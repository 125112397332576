<app-header-style-one></app-header-style-one>

<!-- <app-preloader></app-preloader> -->
 <router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/login' || location == '/register' || location == '/coming-soon')"></app-footer>



<div *ngIf="showBanner" class="cookie-banner">
    <div class="cookie-content">
      <h2>Uso de Cookies</h2>
      <p>
        Este sitio utiliza cookies para mejorar la experiencia del usuario. Al continuar navegando, aceptas nuestra 
        <a routerLink="/politica-de-privacidad" target="_blank">Política de Privacidad</a> y el uso de cookies.
      </p>
      <button (click)="acceptCookies()" class="btn-accept">Aceptar</button>
      <button (click)="declineCookies()" class="btn-decline">Rechazar</button>
    </div>
  </div>