<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8"
                    style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                    <ul class="contact-info">
                        <!--  <li>
                            <a href="https://wa.me/644545588">644545588</a>
                        </li> -->
                        <li>
                            <a href="https://wa.me/34644545588">
                                <img src="assets/img/iconos/whatsapp50_50.png" alt="WhatsApp Icon" class="whatsapp-img">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=100095006798132" target="_blank">
                                <i class='bx bxl-facebook icon-size'></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/clasesdeprogramacion.net_erc/" target="_blank">
                                <i class='bx bxl-instagram icon-size'></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-4">
<!--                     <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Registro</a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-three" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img id="logo" src="assets/img/logo/logo.webp" alt="logo"></a>
                <!--  <a class="navbar-brand" routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a> -->
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a (click)="toggleClass()" routerLink="/" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Inicio</a></li>
                       <!--  <li class="nav-item"><a (click)="toggleClass()" routerLink="/" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Reservar</a></li> -->
                        <!-- TODO: AMPLIACION WEB
                            <li class="nav-item"><a (click)="toggleClass()" routerLink="/categorias" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cursos</a></li>
                        <li class="nav-item"><a (click)="toggleClass()" routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>
                         -->
                        <li class="nav-item"><a (click)="toggleClass()" routerLink="/contacto" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contacto</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>