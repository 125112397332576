<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login/estudiante.svg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img
                                        src="assets/img/logo/logo.webp"
                                        alt="image"></a>
                            </div>
                            <h3>Bienvenido</h3>
                            <p>¿Nuevo en clasesdeprogramacion.net? <a routerLink="/register">Regístrate</a></p>
                            <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input type="email" placeholder="Tú correo electronico" class="form-control"
                                        formControlName="email">
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="Tú contraseña" class="form-control"
                                        formControlName="password">
                                </div>
                                <button type="submit">Entrar</button>
                                <div class="forgot-password">
                                    <a routerLink="/">¿Has olvidado la contraseña?</a>
                                </div>
                                <div class="connect-with-social">
                                    <button type="button" (click)="loginWithGoogle()" class="google"><i
                                            class='bx bxl-google'></i> Conecta con Google</button>
                                    <!--                                     <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Connect with Facebook</button>
                                    <button type="submit" class="twitter"><i class='bx bxl-twitter'></i> Connect with Twitter</button> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>