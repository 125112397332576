<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-code'></i>
                </div>
                <h3>HTML</h3>
                <span>0 Cursos</span>
                <a routerLink="/html" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bxl-css3'></i>
                </div>
                <h3>CSS</h3>
                <span>0 Cursos</span>
                <a routerLink="/css" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bxl-javascript'></i>
                </div>
                <h3>JAVASCRIPT</h3>
                <span>0 Cursos</span>
                <a routerLink="/javascript" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bxl-angular'></i>
                </div>
                <h3>Angular</h3>
                <span>1 Cursos</span>
                <a routerLink="/angular" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-server'></i>
                </div>
                <h3>Backend</h3>
                <span>0 Cursos</span>
                <a routerLink="/backend" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-calculator'></i>
                </div>
                <h3>Matemáticas</h3>
                <span>0 Cursos</span>
                <a routerLink="/matematicas" class="link-btn"></a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
