<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="../../../../../assets/img/index/Eleazar Ramos.webp" class="shadow" alt="image">
                    <img src="../../../../../assets/img/index/Escritorio Profesor.webp" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">SOBRE MÍ</span>
                    <h2>Ingeniería y Futuros en Tecnología</h2>
                    <h6>Con una robusta formación en tecnología, que incluye un grado medio en Sistemas Microinformáticos y Redes, un grado superior en Desarrollo de Aplicaciones Web y estudios en Ingeniería Informática, mi pasión por la programación se refleja en una incesante búsqueda de innovación y soluciones creativas a problemas complejos.</h6>
                    <p>Como educador en la disciplina, abogo por un enfoque práctico de "aprender haciendo". Priorizo la escritura activa de código y la construcción de proyectos desde su concepción hasta su realización. Ofrezco flexibilidad en la programación de mis clases para ajustarme a una amplia gama de horarios y necesidades individuales, siempre con el compromiso de adherir a las mejores prácticas de programación y enseñanza efectiva.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>Cómo Funciona mi Clase de Programación:</h5>
                        <!-- <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and technical competency.</p> -->
                        <!-- <ol style="padding: 20px; font-family: 'Arial', sans-serif;">
                            <li style="background-color: #FF4136; color: white; padding: 10px; margin-bottom: 10px; border-radius: 20px;">Clase de prueba gratuita de 15 minutos para evaluar tus necesidades y objetivos.</li>
                            <li style="background-color: #0074D9; color: white; padding: 10px; margin-bottom: 10px; border-radius: 20px;">Evaluación honesta de si puedo ayudarte a alcanzar esos objetivos.</li>
                            <li style="background-color: #2ECC40; color: white; padding: 10px; margin-bottom: 10px; border-radius: 20px;">Trabajo conjunto en tu proyecto o concepto.</li>
                            <li style="background-color: #FF851B; color: white; padding: 10px; margin-bottom: 10px; border-radius: 20px;">Flexibilidad para contratar horas adicionales o paquetes según tus necesidades.</li>
                        </ol> -->
                        <ol style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #333;">
                            <li style="border-left: 4px solid #4CAF50; padding: 10px; margin-bottom: 10px;">Clase de prueba gratuita de 15 minutos para evaluar tus necesidades y objetivos.</li>
                            <li style="border-left: 4px solid #2196F3; padding: 10px; margin-bottom: 10px;">Evaluación honesta de si puedo ayudarte a alcanzar esos objetivos.</li>
                            <li style="border-left: 4px solid #FFC107; padding: 10px; margin-bottom: 10px;">Trabajo conjunto en tu proyecto o concepto.</li>
                            <li style="border-left: 4px solid #FF5722; padding: 10px; margin-bottom: 10px;">Adaptabilidad total a tu horario y necesidades específicas.</li>

                        </ol>
                        <!-- <ol style="font-family: 'Roboto', sans-serif;">
                            <li style="background: linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(255,128,0,1) 100%); color: white; padding: 15px; border-radius: 5px; margin-bottom: 10px;">Clase de prueba gratuita de 15 minutos para evaluar tus necesidades y objetivos.</li>
                            <li style="background: linear-gradient(90deg, rgba(0,230,118,1) 0%, rgba(41,121,255,1) 100%); color: white; padding: 15px; border-radius: 5px; margin-bottom: 10px;">Evaluación honesta de si puedo ayudarte a alcanzar esos objetivos.</li>
                            <li style="background: linear-gradient(90deg, rgba(103,58,183,1) 0%, rgba(250,104,0,1) 100%); color: white; padding: 15px; border-radius: 5px; margin-bottom: 10px;">Trabajo conjunto en tu proyecto o concepto.</li>
                            <li style="background: linear-gradient(90deg, rgba(255,23,68,1) 0%, rgba(255,82,82,1) 100%); color: white; padding: 15px; border-radius: 5px; margin-bottom: 10px;">Flexibilidad para contratar horas adicionales o paquetes según tus necesidades.</li>
                        </ol> -->
         
                        
                        
                    </div>
                    <a style="display: flex; align-items: center; gap: 10px;" href="https://wa.me/644545588" class="default-btn">
                        <span class="label">Reserva 15 minutos ¡gratis!</span>
                        <img src="assets/img/iconos/whatsapp50_50.png" alt="WhatsApp Icon" style="vertical-align: middle; margin-right: 8px; width: 20px; height: 20px;">
                        
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>