<!-- <app-header-style-two></app-header-style-two> -->

<div class="page-title-area item-categoria">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li>Cursos</li>
            </ul>
            <h2>Lista de categorías</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-code'></i>
                    </div>
                    <h3>HTML</h3>
                    <span>0 Cursos</span>
                    <a routerLink="/html" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxl-css3'></i>
                    </div>
                    <h3>CSS</h3>
                    <span>0 Cursos</span>
                    <a routerLink="/css" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxl-javascript'></i>
                    </div>
                    <h3>JAVASCRIPT</h3>
                    <span>0 Cursos</span>
                    <a routerLink="/javascript" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxl-angular'></i>
                    </div>
                    <h3>Angular</h3>
                    <span>1 Cursos</span>
                    <a routerLink="/angular" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-server'></i>
                    </div>
                    <h3>Backend</h3>
                    <span>0 Cursos</span>
                    <a routerLink="/backend" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-calculator'></i>
                    </div>
                    <h3>Matemáticas</h3>
                    <span>0 Cursos</span>
                    <a routerLink="/matematicas" class="link-btn"></a>
                </div>
            </div>
<!--             <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                    </div>
                    <h3>Humanities</h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div> -->
<!--             <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div>
                    <h3>Science</h3>
                    <span>12 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>