<!-- <app-header-style-two></app-header-style-two> -->

<div class="page-title-area item-contacto">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Contacto</li>
            </ul>
            <h2>Contacta con nosotros</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:info@clasesdeprogramacion.net">info@clasesdeprogramacion.net</a></p>
                    <!--  <p><a href="mailto:ednuv@hello.com">ednuv@hello.com</a></p> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Localización</h3>
                    <p><a href="https://www.google.es/maps/place/Castell%C3%B3n+de+la+Plana,+Castell%C3%B3n/"
                            target="_blank">Vila-Real 12540</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Teléfono</h3>
                    <p><a href="tel:+34644545588">+34 644 54 55 88</a></p>
                    <!-- <p><a href="tel:2414524526">+241 452 4526</a></p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contacto</span>
            <h2>Envíanos un Mensaje para Cualquier Consulta</h2>
            <p>Si tienes alguna pregunta o inquietud, no dudes en comunicarte con nosotros. Estamos aquí para ayudarte.
                Para obtener una respuesta más rápida, contáctanos a través de <a
                    href="https://wa.me/644545588">WhatsApp</a>.</p>
        </div>
        <div class="contact-form">
            <form [formGroup]="contactForm" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input formControlName="name" type="text" class="form-control" id="name"
                                placeholder="Tu nombre">
                            <div class="alert alert-danger"
                                *ngIf="contactForm.controls.name.touched && !contactForm.controls.name.valid">
                                <div *ngIf="contactForm.controls.name.errors?.required">El nombre es obligatorio.</div>
                                <div *ngIf="contactForm.controls.name.errors?.minlength">El nombre debe tener al menos
                                    {{ contactForm.controls.name.errors?.minlength.requiredLength }} caracteres.</div>
                                <div *ngIf="contactForm.controls.name.errors?.maxlength">El nombre no puede exceder los
                                    50 caracteres.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input formControlName="email" required name="email" type="text" class="form-control"
                                id="email" placeholder="Tu dirección de correo electrónico">
                            <div class="alert alert-danger"
                                *ngIf="contactForm.controls.email.touched && !contactForm.controls.email.valid">
                                <div *ngIf="contactForm.controls.email.errors?.required">El email es obligatorio.</div>
                                <div *ngIf="contactForm.controls.email.errors?.email">Tiene que ser un email</div>
                                <!-- <div *ngIf="contactForm.controls.name.errors?.maxlength">El nombre no puede exceder los 50 caracteres.</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input formControlName="number" name="number" type="text" class="form-control" id="number"
                                placeholder="Tu número de teléfono (Opcional)">
                            <!--  <div class="alert alert-danger" *ngIf="contactForm.controls.number.touched && !contactForm.controls.number.valid"> -->
                            <!-- <div *ngIf="contactForm.controls.number.errors?.required">El telefono es obligatorio.</div> -->
                            <!-- <div *ngIf="contactForm.controls.email.errors?.email">Tiene que ser un email</div> -->
                            <!-- <div *ngIf="contactForm.controls.name.errors?.maxlength">El nombre no puede exceder los 50 caracteres.</div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input formControlName="subject" required name="subject" type="text" class="form-control"
                                id="subject" placeholder="Asunto">
                            <div class="alert alert-danger"
                                *ngIf="contactForm.controls.subject.touched && !contactForm.controls.subject.valid">
                                <div *ngIf="contactForm.controls.subject.errors?.required">El asunto es obligatorio.
                                </div>
                                <!-- <div *ngIf="contactForm.controls.email.errors?.email">Tiene que ser un email</div> -->
                                <!-- <div *ngIf="contactForm.controls.name.errors?.maxlength">El nombre no puede exceder los 50 caracteres.</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea formControlName="message" required name="message" id="message" cols="30" rows="5"
                                class="form-control" placeholder="Tu mensaje..."></textarea>
                            <!--  <div class="alert alert-danger" *ngIf="message.touched && !name.valid">El mensaje es obligatorio.</div> -->
                            <div class="alert alert-danger"
                                *ngIf="contactForm.controls.message.touched && !contactForm.controls.message.valid">
                                <div *ngIf="contactForm.controls.message.errors?.required">El mensaje es obligatorio.
                                </div>
                                <!-- <div *ngIf="contactForm.controls.email.errors?.email">Tiene que ser un email</div> -->
                                <!-- <div *ngIf="contactForm.controls.name.errors?.maxlength">El nombre no puede exceder los 50 caracteres.</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Enviar
                                Mensaje</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                        <div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="imagen"></div>
</div>


<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12204.434456852542!2d-0.05157898228117703!3d39.98636897941961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a131f5e7c8c3f1%3A0x9349eac2b48a8c5d!2sCastell%C3%B3n%20de%20la%20Plana%2C%20Castell%C3%B3n%2C%20Espa%C3%B1a!5e0!3m2!1ses!2sus!4v1629919419435!5m2!1ses!2sus"
        style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>