<div class="privacy-policy-container">
    <h1>Política de Privacidad</h1>
    <p>
      En Clasesdeprogramación.net, accesible desde <a href="https://clasesdeprogramacion.net/">https://clasesdeprogramacion.net/</a>, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Esta política de privacidad describe los tipos de información que recopilamos y registramos en Clases de Programación y cómo la utilizamos.
    </p>
    <h2>Información que Recopilamos</h2>
    <h3>Información Personal</h3>
    <p>
      Al utilizar nuestros servicios, podemos pedirle que nos proporcione cierta información de identificación personal que puede ser utilizada para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:
    </p>
    <ul>
      <li>Nombre</li>
      <li>Dirección de correo electrónico</li>
      <li>Número de teléfono</li>
      <li>Información de registro y login</li>
    </ul>
    <h3>Datos de Uso</h3>
    <p>
      También podemos recopilar información sobre cómo se accede y se utiliza el servicio ("Datos de Uso"). Estos Datos de Uso pueden incluir información como la dirección de Protocolo de Internet de su computadora (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, identificadores únicos de dispositivos y otros datos de diagnóstico.
    </p>
    <h2>Uso de la Información</h2>
    <p>
      Utilizamos la información recopilada de diversas maneras, incluyendo:
    </p>
    <ul>
      <li>Para proporcionar, operar y mantener nuestro sitio web</li>
      <li>Para mejorar, personalizar y expandir nuestro sitio web</li>
      <li>Para comprender y analizar cómo utiliza nuestro sitio web</li>
      <li>Para desarrollar nuevos productos, servicios, características y funcionalidades</li>
      <li>Para comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluyendo para el servicio al cliente, para proporcionarle actualizaciones y otra información relacionada con el sitio web, y con fines promocionales y de marketing</li>
      <li>Para enviarle correos electrónicos</li>
      <li>Para detectar y prevenir fraudes</li>
    </ul>
    <h2>Almacenamiento y Seguridad de los Datos</h2>
    <p>
      Sus datos se almacenan en Firebase, un servicio proporcionado por Google. Firebase nos proporciona la infraestructura necesaria para almacenar datos de manera segura y confiable.
    </p>
    <h3>Seguridad de los Datos</h3>
    <p>
      La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.
    </p>
    <h2>Uso de Cookies</h2>
    <p>
      Clases de Programación utiliza "Cookies" para identificar las áreas de nuestro sitio web que ha visitado. Una cookie es un pequeño fragmento de datos almacenado en su computadora o dispositivo móvil por su navegador web. Usamos cookies para mejorar la funcionalidad y el rendimiento de nuestro sitio web y para proporcionarle una experiencia personalizada.
    </p>
    <h3>Tipos de Cookies que Utilizamos</h3>
    <ul>
      <li><strong>Cookies Necesarias:</strong> Estas cookies son esenciales para que usted pueda navegar por nuestro sitio web y utilizar sus funciones.</li>
      <li><strong>Cookies de Preferencias:</strong> Estas cookies permiten que nuestro sitio web recuerde las elecciones que ha hecho en el pasado.</li>
      <li><strong>Cookies de Rendimiento:</strong> Estas cookies recopilan información sobre cómo utiliza nuestro sitio web.</li>
      <li><strong>Cookies de Marketing:</strong> Estas cookies se utilizan para rastrear a los visitantes en los sitios web. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual.</li>
    </ul>
    <h3>Cookies de Terceros</h3>
    <p>
      Utilizamos Google Analytics para analizar el uso de nuestro sitio web. Google Analytics genera información estadística y otra información sobre el uso del sitio web mediante cookies, que se almacenan en las computadoras de los usuarios. La información generada relacionada con nuestro sitio web se utiliza para crear informes sobre el uso del sitio web. Google almacenará y utilizará esta información. La política de privacidad de Google está disponible en: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
    </p>
    <h2>Sus Derechos de Privacidad</h2>
    <h3>Derecho a Acceder, Rectificar o Suprimir</h3>
    <p>
      Usted tiene derecho a acceder, corregir o eliminar sus datos personales. Puede solicitar la eliminación de sus datos personales enviándonos un correo electrónico a <a href="mailto:sixen25@gmail.com">sixen25@gmail.com</a>.
    </p>
    <h3>Derecho a la Portabilidad de los Datos</h3>
    <p>
      Usted tiene derecho a solicitar una copia de los datos que hemos recopilado sobre usted. Puede solicitar esta información enviándonos un correo electrónico a <a href="mailto:sixen25@gmail.com">sixen25@gmail.com</a>.
    </p>
    <h2>Cambios en esta Política de Privacidad</h2>
    <p>
      Podemos actualizar nuestra política de privacidad periódicamente. Le notificaremos cualquier cambio publicando la nueva política de privacidad en esta página. Le informaremos a través de correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la "fecha de vigencia" en la parte superior de esta política de privacidad.
    </p>
    <h2>Contacto</h2>
    <p>
      Si tiene alguna pregunta sobre esta política de privacidad, puede contactarnos a través de:
    </p>
    <ul>
      <li>Correo electrónico: <a href="mailto:sixen25@gmail.com">sixen25@gmail.com</a></li>
      <li>Dirección: [tu dirección]</li>
    </ul>
  </div>
  