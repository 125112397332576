<section class="mission-area ptb-100">
    <picture>
        <source media="(min-width: 992px)" srcset="../../../../../assets/img/index/programadores.svg">
        <source media="(min-width: 768px)" srcset="../../../../../assets/img/index/programadores.svg">
        <img src="../../../../../assets/img/index/programadores.svg" alt="Programadores" role="presentation" aria-hidden="true">
      </picture>
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Embárcate en un Viaje Digital</span>
                <h2>Clases de programación particulares</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>El Camino hacia la Maestría en Desarrollo Web</h3>
                        <p>Avanza en tu carrera tecnológica con tutorías personalizadas en JavaScript, TypeScript, Angular, y JAVA. Perfectas para estudiantes de DAM, DAW, universitarios y proyectos de fin de grado. ¡Aprende a tu propio ritmo y según tus necesidades!</p>
                        <p>Con mi apoyo integral, estarás en el camino hacia el éxito académico y profesional. Mis servicios son flexibles, asequibles y diseñados para todos los niveles, desde principiantes hasta desarrolladores avanzados.</p>
                        <a style="display: flex; align-items: center; gap: 10px;" href="https://wa.me/644545588" class="default-btn">
                            <span class="label">Reserva 15 minutos ¡gratis!</span>
                            <img src="assets/img/iconos/whatsapp50_50.png" alt="WhatsApp Icon" style="vertical-align: middle; margin-right: 8px; width: 20px; height: 20px;">
                            
                        </a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>Personalización y Flexibilidad en Tu Educación</h3>
                        <p>Ya sea que representes a una empresa o busques mejorar individualmente, mis clases se personalizan para cumplir tus objetivos específicos. Con métodos adaptativos y orientación experta, impulsaré tu capacidad para enfrentar desafíos tecnológicos actuales.</p>
                        <p>Disfruta de transacciones seguras a través de Bizum y organiza tu horario con una respuesta rápida por WhatsApp. ¡Inicia tu aventura en la programación con un guía dedicado y experimentado!</p>
                        <a style="display: flex; align-items: center; gap: 10px;" href="https://wa.me/644545588" class="default-btn">
                            <span class="label">Consulta disponibilidad y precios</span>
                            <img src="assets/img/iconos/whatsapp50_50.png" alt="WhatsApp Icon" style="vertical-align: middle; margin-right: 8px; width: 20px; height: 20px;">
                            
                        </a>
                    </ng-template>
                </owl-carousel-o>
                
            </div>
        </div>
    </div>
</section>
