import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header-style-one',
  templateUrl: './header-style-one.component.html',
  styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
        /* this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.cerrarMenu();
            }
          }); */
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    /* cerrarMenu() {
        const navbar = document.getElementById('navbarSupportedContent');
        if (navbar) {
        navbar.classList.toggle('show'); // Cambia la clase "show" en el elemento
        }
    } */

}