<section class="hero-section">
    <div class="container">
      <!-- Texto -->
      <div class="hero-content">
        <span class="sub-title">¡Aprende Java con un mentor experto!</span>
        <h1>Domina <span>Java</span> desde cero hasta avanzado</h1>
        <p>Clases personalizadas 1:1 para DAM, DAW, universitarios y bootcamps. 100% prácticas y adaptadas a tu ritmo.</p>
        
        <!-- Botones Mejorados -->
        <div class="hero-buttons">
          <a href="https://calendly.com/sixen25/asesoria-gratis-de-15-minutos" class="primary-btn">
            🚀 Reserva 15 min GRATIS para asesorarte
          </a>
          <a href="https://wa.me/34644545588" class="secondary-btn">
            📩 Consultar por WhatsApp
          </a>
        </div>
      </div>
  
      <!-- Imagen -->
      <div class="hero-image">
        <img src="assets/img/index/programadores.svg" alt="Programadores">
      </div>
    </div>
</section>
